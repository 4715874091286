.footer {
  @apply w-full justify-center items-center bg-gray-900 pt-[30px] pb-[60px];
  background: rgba(19, 21, 23, 1);
}

.footer-info {
  @apply w-full flex flex-col justify-between items-center text-gray-800 leading-6 h-[51px] maxSmPhone:px-[10px];
}

.footer-info:last-child {
  @apply mt-5 tabSm:mt-0;
}

.verified {
  @apply font-bold text-[#a6aeb4] text-base;
}

.token {
  @apply text-[12px] minPhone:text-[0.75em] lgPhone:text-[14px] text-[#A6AEB4];
}

.footer-info-section {
  @apply flex flex-col w-full mt-[30px] tabSm:flex-row tabMd:w-[1010px];
}
