.modal {
    @apply z-[999] fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto;
    @apply backdrop-blur-xl;
}

.modal-dialog {
    @apply relative top-1/4 mx-auto w-max max-w-screen-sm pointer-events-none;
}

.modal-header {
    @apply absolute z-[999] top-3 right-5 p-1 rounded-t-md;
}

.modal-content {
    @apply shadow-lg relative flex flex-col w-full pointer-events-auto bg-gray-900 bg-clip-padding rounded-[26px] outline-none;
}

.modal-body {
    @apply relative px-6;
}

.modal-title {
    @apply text-xl font-medium leading-normal text-white font-bold;
}

.modal-footer {
    @apply flex flex-shrink-0 flex-wrap items-center justify-center p-4;
}
