@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

* {
    @apply text-white text-sm font-medium;
    font-family: 'Helvetica Neue', sans-serif;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    outline: 0 !important;
}
