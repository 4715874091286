.maintenance {
    @apply fixed min-w-full min-h-full;
    @apply left-0 right-0 bottom-0 left-0;
    @apply bg-gray-900;
    @apply flex flex-col justify-center items-center;
}

.maintenance img {
    @apply h-28 mb-10;
}

.message {
    @apply text-white font-bold text-7xl;
}