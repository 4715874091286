.sidenav {
    @apply fixed left-0 top-0;
    @apply transform duration-300;
    @apply w-full h-full bg-gray-900;
    @apply p-3;
    @apply z-[1000]
}

.header-mobile {
    @apply flex flex-row justify-between items-center w-full mb-3;
}

.logo-mobile {
    @apply flex h-full items-center;
}

.logo-mobile img {
    @apply h-[50px] mr-2 mb-2.5;
}

.logo-mobile span {
    @apply text-white text-[9px] tracking-[5px];
    font-family: 'Jost', sans-serif;
}

.close-mobile {
    @apply cursor-pointer;
}

.menu-mobile {
    @apply flex items-center py-24 justify-center;
}

.menu-mobile .connect {
    @apply min-w-[300px];
}

.social-mobile {
    @apply pt-5 w-full border-t-[1px] flex flex-row justify-center items-center;
    border-color: rgba(255, 255, 255, 0.2);
}

.social-mobile a {
    @apply ml-4;
}

.social-mobile a {
    @apply w-[40px] h-[40px] flex items-center justify-center rounded;
    background-color: rgba(255,255,255, 0.1);
}

.social-mobile li:first-child {
    @apply ml-0;
}