.toast {
    @apply fixed top-0 left-[50%] translate-x-[-50%];
    @apply mt-28 h-fit px-8 py-3 rounded max-w-xl;
    @apply duration-300 opacity-0;
}

.toast.show {
    @apply opacity-100;
}

.toast.success {
    @apply bg-green;
}

.toast.error {
    @apply bg-red;
}

.toast .message {
    @apply text-[16px] font-medium text-white;
}