.header {
  @apply h-[72px] w-full bg-[#000000] px-4;
  @apply flex flex-row justify-between items-center;
}

.logo {
  @apply flex h-full items-center;
}

.logo span {
  @apply text-white text-[9px] tracking-[5px];
  font-family: 'Jost', sans-serif;
}

.right-block {
  @apply flex flex-row justify-end items-center;
  @apply hidden md:flex;
}

.social {
  @apply flex flex-row justify-end items-center;
}

.social a {
  @apply w-[40px] h-[40px] flex items-center justify-center rounded mr-4;
  background-color: rgba(255, 255, 255, 0.1);
}

.social a img {
  @apply opacity-30 hover:opacity-100;
}

.connect {
  @apply bg-blue-500 text-white font-bold text-xs uppercase rounded py-3 px-5;
  @apply duration-300 opacity-90 hover:opacity-100;
}

.burger {
  @apply h-[20px] space-y-1.5 cursor-pointer;
  @apply md:hidden;
}

.burger:hover .line {
  @apply duration-300 w-6;
}

.burger .line {
  @apply block h-0.5 bg-white;
}
