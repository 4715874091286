.card {
  width: calc(100% - 40px);
  @apply mt-[30px] tabMd:mt-0 tabMd:w-[350px] tabMd:mx-0 tabMd:min-h-[334px] mdPhone:h-[368px] mdPhone:max-w-[315px] maxTabMd:w-full;
  border-radius: 16px;

  background-color: rgba(59, 170, 167, 0.35);

  display: flex;
  flex-direction: column;
}

.card-box {
  @apply px-[25px] flex flex-row justify-between tabMd:flex-col tabMd:min-h-[334px] lgPhone:px-[45px] mdPhone:h-[334px] mdPhone:w-[315px] mdPhone:flex-col;
  width: 100%;
  padding-top: 35px;
  padding-bottom: 30px;
  border-radius: 40px;
}

.card-shadow {
  @apply h-[280px] w-[386px] rounded-[15px];
  background: rgba(59, 170, 167, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.card h5 {
  @apply text-[24px] maxLgPhone:text-[20px] tabMd:text-[26px] leading-[29px] text-white font-medium tracking-wide;
}

.card-points {
  flex-grow: 1;
  margin: 20px 0 25px;
}

.card-point {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 14px;

  flex-grow: 1;
}

.card-point:last-child {
  margin-bottom: 0;
}

.card-point span:first-child {
  @apply w-[100px] tabSm:w-[120px] tabMd:w-[104px] mdPhone:w-[114px] maxSmPhone:w-[85px];
  opacity: 0.7;
  font-weight: 500;
}

.card-point span {
  @apply text-[16px] maxSmPhone:text-[13px];
}

.card-button {
  @apply border border-white rounded mb-[33px] text-blue-500 bg-white tabMd:mb-4 mdPhone:mb-[20px];
  width: 100%;
  max-width: 260px;
  font-size: 14px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 40px;
  -webkit-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
}

.card-button.buy {
  color: white;
  background: #54a9a7;
  border: none;
}
.card-button.buy:hover {
  background: #35c8c4;
}

.counter {
  color: #d3d3d3;
  font-size: 12px;
}

.circle-number {
  @apply h-[30px] w-[30px] mb-[20px] z-[2] flex items-center pl-[10px] bg-[#3BAAA7] font-bold text-[16px] rounded-[50%] mr-5 tabSm:mb-0 mdPhone:mb-[7px];
}

.card-buttons {
  @apply mt-[60px] tabMd:mt-0 lgPhone:w-[252px] mdPhone:mt-[20px];
}

.card-labels {
  @apply maxLgPhone:w-[233px] mdPhone:w-full;
}
