.main-container {
  padding-top: 60px;
  min-height: calc(100vh - 389px);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(44.44%, #08090a),
    color-stop(64.81%, rgba(0, 0, 0, 0.5)),
    color-stop(99.15%, rgba(0, 0, 0, 0.3))
  );
  background: linear-gradient(
    0deg,
    #08090a 55.44%,
    rgba(0, 0, 0, 0.5) 64.81%,
    rgba(0, 0, 0, 0.3) 99.15%
  );
}

.video-container {
  @apply absolute left-0 top-0 right-0 bottom-0;
  @apply z-[-100];
}

.video-container video {
  @apply fixed right-0 bottom-0 min-w-full min-h-full;
  @apply w-auto h-auto bg-cover z-[-100];
  max-width: none;
  max-height: none;
}

.box {
  @apply py-0 px-[15px] tabMd:px-[16px] lgPhone:px-[30px];
  max-width: 1252px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-info {
  @apply flex flex-col justify-center items-center px-5;
}

.main-info h1 {
  @apply text-center text-[60px] leading-[100%] maxLgPhone:text-[45px] tabMd:text-[60px] tabMd:leading-[80px] mb-6 font-medium leading-[120%] tracking-[0.005em];
}

.main-info h5 {
  @apply text-justify text-[16px] text-white font-bold tracking-wide sm:text-center leading-[150%];
  max-width: 802px;
}

.phase-slider {
  margin: 75px -7.5px 0;
}

.phase-slider .slick-list:after {
  content: '';
  position: absolute;
  margin: 0;
  width: 96px;
  top: 0;
  right: 0;
  bottom: 0;
  background: -webkit-gradient(linear, right top, left top, from(#08090a), to(rgba(0, 0, 0, 0)));
  background: linear-gradient(270deg, #08090a 0%, rgba(0, 0, 0, 0) 100%);
  z-index: 0;
}

.arrow {
  position: absolute;
  top: 45%;
  right: 0;
  width: 40px;
  height: 40px;
  transition: opacity 0.3s, background 0.3s;
  z-index: 100;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(12px);
  border-radius: 5px;
}

.arrow:before {
  content: '';
  color: white;
  position: absolute;
  margin: 0;
  width: 17px;
  height: 17px;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  background-image: url(../../../public/img/icons/arrow.svg);
}

.arrow:hover {
  background: rgba(255, 255, 255, 1);
}

.left {
  left: 0;
  right: auto;
  transform: rotate(180deg);
}

.card-description {
  @apply my-[25px] w-full px-[25px] sm:my-0 pt-0 md:pt-[35px] tabMd:w-[594px] tabMd:px-[45px] lgPhone:px-[40px];

  padding-top: 35px;
  padding-bottom: 30px;
  background-color: rgba(59, 170, 167, 0.1);
  border-radius: 15px;
}

.card-description-point {
  @apply flex-col mb-0 w-[120px] tabSm:flex-row maxSmPhone:w-[40%] mb-[20px] mr-[10px] smPhone:mr-[20px] tabSm:h-[30px] tabSm:mr-0 lgPhone:w-[121px] tabMin:w-[146px] tabMd:w-full;
  @apply tabSm:w-full;
  display: flex;
  align-items: flex-start;
  line-height: 150%;
  font-size: 16px;
}

.card-line {
  @apply absolute h-0 maxSmPhone:w-[106px] w-[115px] mt-[15px] ml-[30px] border-t border-white tabSm:hidden;
  --tw-border-opacity: 0.2;
}
.card-line:last-child {
  @apply maxSmPhone:w-[80px];
}

.card-description-point h3 {
  line-height: 150%;
}

.card-description-points {
  @apply flex flex-row flex-wrap tabSm:flex-col tabSm:flex-nowrap;
  margin: 25px 0 10px;
}

.main-footer {
  max-height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  letter-spacing: 0.5px;
  margin-bottom: 30px;
}

.footer-note {
  @apply flex w-full text-[#a6aeb4] flex-wrap text-left tabMd:justify-between tabSm:pl-[47px] tabMd:justify-center tabMd:max-w-[1010px] tabSm:max-w-[345px];
}

.vertical-divide-line {
  @apply mx-0 tabMd:mx-0 tabMd:h-[40px] smPhone:mx-[30px];
  border-left: 1px solid #707070;
}

.horizontal-divide-line {
  margin-top: 10px;
  width: 100px;
  border-top: 1px solid #707070;
}

.help {
  margin-top: 10px;
  text-align: center;
  padding: 10px 20px;
}

.help h1 {
  @apply font-bold text-[#a6aeb4] text-[14px];
}

.help h5 {
  margin-top: 3px;
  font-size: 14px;
  max-width: 630px;
  line-height: 24px;
  font-weight: 400;
  color: #a6aeb4;
}

.help a {
  font-size: 14px;
}

.card-description h5 {
  @apply text-[24px] maxLgPhone:text-[20px] tabMd:text-[26px] text-white font-medium leading-[30px];
}

.card-description h2,
p {
  @apply text-white font-normal text-base flex items-start tabSm:items-center;
}

.main-content {
  @apply mt-0 flex-col sm:mt-[50px] tabMd:flex-row maxTabMd:items-center;
  width: 100%;
  display: flex;
  justify-content: center;

  column-gap: 25px;
}

a {
  color: #54a9a7;
  text-decoration: underline;
  font-size: 1rem;
}

.metaverse-section {
  @apply py-[35px] max-w-[1010px] my-9 rounded-[15px] flex items-center tabMd:h-[170px];
  background: rgba(59, 170, 167, 0.1);
}
.metaverse-section img {
  @apply mb-[35px] lgPhone:mb-0;
  height: 190px;
  width: 190px;
  margin-left: 40px;
}
.metaverse-section-border {
  @apply h-[170px] absolute w-[156px];
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  background: rgba(59, 170, 167, 0.25);
  filter: blur(100px);
}
.right {
  left: calc(100% - 312px);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.metaverse-text-section {
  @apply max-w-[704px] h-full text-white ml-10 flex items-center lgPhone:items-start flex-col justify-center pr-[40px];
}
.metaverse-text-section h1 {
  @apply font-medium text-[24px] maxLgPhone:text-[20px] tabMd:text-[26px] leading-[24px] maxLgPhone:text-center lgPhone:leading-[120%];
}
.metaverse-text-section h2 {
  @apply font-normal text-base maxLgPhone:text-center;
}
.horizontal-divide-line {
  @apply my-[10px] w-[72px] border-t border-white;
}
.title-line {
  margin: 0 !important;
  width: 246px !important;
}
.metaverse-section-container {
  @apply flex flex-col items-center lgPhone:flex-row;
}
.card-description-point-container {
  @apply flex tabSm:block;
}
.last {
  margin-right: 0 !important;
}
